<template>
  <div class="flex items-center">
    {{ getOfficineById.NomOffic }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererOfficineDestination',
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    getOfficineById () {
      return this.$store.getters['officine/getOfficineById'](this.params.data.Officine_destination)
    }
  },
  methods: {
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
    this.getOfficines()
  }
}
</script>
