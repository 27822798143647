<template>
  <div class="flex items-center">
    {{ montantArticle }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererMontantCommande',
  computed: {
    montantArticle () {
      /* Somme des lignes du detail commande */
      const total = this.params.data.details.reduce(function (accumulator, detail) {
        return accumulator + (detail.PrixVenteTTC * detail.stock)
      }, 0)
      /* Formatage du calcul */
      const val = (total / 1).toFixed(2).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
  }
}
</script>
