<template>
  <div id="page-fournisseur-commande-list">
    <div class="vx-card p-6" id="user-list-body">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ articles_transferer.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : articles_transferer.length }} of {{ articles_transferer.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-select placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="currentArticleTransfertSelect()">
            <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
          </vs-select>
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Recherche..." />
          <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

          <!-- ACTION - DROPDOWN -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">

            <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
              <span class="mr-2 leading-none">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Print</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>CSV</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="articles_transferer"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import passwordMeter from 'vue-simple-password-meter'

// Store Module

// Cell Renderer
import CellRendererActionsArticles from './cell-renderer/CellRendererActionsArticles.vue'
import CellRendererOfficine from './cell-renderer/CellRendererOfficine.vue'
import CellRendererOfficineDestination from './cell-renderer/CellRendererOfficineDestination.vue'
import CellRendererDateTransfert from './cell-renderer/CellRendererDateTransfert.vue'
import CellRendererMontantArticle from './cell-renderer/CellRendererMontantArticle.vue'


export default {
  components: {
    AgGridVue,
    vSelect,
    passwordMeter,

    // Cell Renderer
    CellRendererActionsArticles,
    CellRendererOfficine,
    CellRendererOfficineDestination,
    CellRendererDateTransfert,
    CellRendererMontantArticle
  },
  data () {
    return {
      activeUserInfos: null,
      // Filter Options
      idOfficine: '',
      searchQuery: '',

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          width: 100,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          headerName: 'Code Transf',
          field: 'code_transfert',
          filter: true,
          width: 250
        },
        {
          headerName: 'Origine',
          field: 'Officine_origine',
          filter: true,
          width: 250,
          cellRendererFramework: 'CellRendererOfficine'
        },
        {
          headerName: 'Destination',
          field: 'Officine_destination',
          filter: true,
          width: 250,
          cellRendererFramework: 'CellRendererOfficineDestination'
        },
        {
          headerName: 'Date transfert',
          field: 'createdAt',
          filter: true,
          width: 250,
          cellRendererFramework: 'CellRendererDateTransfert'
        },
        {
          headerName: 'Montant TTC',
          field: 'details',
          filter: true,
          width: 250,
          cellRendererFramework: 'CellRendererMontantArticle'
        },
        {
          headerName: 'Actions',
          field: '_id',
          width: 200,
          cellRendererFramework: 'CellRendererActionsArticles'
        }
      ],

      // Cell Renderer Components
      components: {

      }
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.login !== '' && this.password !== null && this.IdPersonel !== '' && this.fonction !== ''
    },

    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    officines () {
      return this.$store.state.officine.officines
    },
    articles_transferer () {
      return this.$store.state.stock.articles_transferer
    }
  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    currentArticleTransfertSelect () {
      console.log(this.idOfficine)
      this.getTransfertArticleByOfficine(this.idOfficine)
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine

      this.getTransfertArticleByOfficine(this.idOfficine)
    },
    getTransfertArticleByOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('stock/getTransfertArticleByOfficine', id)
        .then(() => {

        })
        .catch(err => { console.log(err) })
        .finally(() => {
          this.$vs.loading.close()
        })
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.getOfficines()
    this.activeUserInfo()
  }

}

</script>

<style lang="scss">
#page-fournisseur-commande-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

/* .header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    > button {
      margin-left: 10px;
    }
  }
}

.footer-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > button {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 0px !important;
  }
} */
</style>
