<template>
  <div>
    {{ this.params.data.createdAt | moment("calendar", "July 10 2011") }}
  </div>
</template>
<script>
export default {

}
</script>
